import logo from '../assets/logo-wekn.png';
import '../styles/App.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          WeKn is coming...
        </p>
        <a
          className="App-link"
          href="mailto:matthieu@wekn.fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
      </header>
    </div>
  );
}

export default App;
